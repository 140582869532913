import React, { useCallback, useMemo, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const hero = [
  {
    title: "Progetti",
    description: "Una sintesi dei principali progetti realizzati su Liferay",
  },
];
const breadcrumb = [{ title: "Home", link: "/" }, { title: "Progetti" }];

const Progetti = ({ data: { progetti }, location }) => {
  const [clientiMap, setClientiMap] = useState({});
  const toggleMostra = useCallback((e) => {
    const { name } = e.target;
    setClientiMap((current) => ({ ...current, [name]: !current[name] }));
  }, []);

  const [page, setPage] = useState(1);
  const allProgetti = progetti.nodes;
  const paginatedProgetti = useMemo(() => allProgetti.slice(0, page * 5), [page, allProgetti]);
  const showMore = useCallback(() => setPage((current) => current + 1), []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Progetti`} description={`Progetti realizzati su Liferay DXP`} />

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {paginatedProgetti.map((progetto, i) => (
                <div key={i}>
                  <div className="box-progetto">
                    <div className="row">
                      <div className={`col-12 col-md-6${i % 2 ? " offset-xl-1 order-md-2" : ""}`}>
                        <div className="box-progetto__bg d-flex justify-content-center align-items-center">
                          {progetto.immagineSfondo && (
                            <GatsbyImage
                              image={getImage(progetto.immagineSfondo)}
                              alt={progetto.nome}
                              className="img-bg"
                            />
                          )}
                          <div className="box-progetto__logo d-flex justify-content-center align-items-center">
                            {progetto.logoBianco && (
                              <GatsbyImage
                                image={getImage(progetto.logoBianco)}
                                alt={progetto.nome}
                                className="box-progetto__img"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-md-6 d-flex align-items-center col-xl-5${
                          i % 2 ? "" : " offset-xl-1"
                        }`}
                      >
                        <div>
                          <div className="box-progetto__title">{progetto.nome}</div>
                          <div
                            className="box-progetto__text"
                            dangerouslySetInnerHTML={{
                              __html: progetto.descrizioneBreve.childMarkdownRemark.html,
                            }}
                          ></div>
                          {progetto.descrizioneAggiuntiva && (
                            <>
                              <div
                                className={`box-progetto__text${
                                  clientiMap[progetto.nome] ? "" : " d-none"
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: progetto.descrizioneAggiuntiva.childMarkdownRemark.html,
                                }}
                              ></div>
                              <button
                                className="box-progetto__more"
                                name={progetto.nome}
                                onClick={toggleMostra}
                              >
                                {clientiMap[progetto.nome] ? "Vedi di meno" : "Vedi di più"}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!!paginatedProgetti && paginatedProgetti.length < allProgetti.length && (
            <div className="row">
              <div className="col-12 text-center">
                <div className="num-results">
                  {paginatedProgetti.length} di {allProgetti.length}
                </div>
                <button onClick={showMore} className="btn-link btn-link--secondary">
                  Carica altri progetti
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Progetti;

export const query = graphql`
  query {
    progetti: allContentfulCliente(filter: { node_locale: { eq: "it" } }, sort: { fields: nome }) {
      nodes {
        nome
        logoBianco {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
        }
        immagineSfondo {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
        }
        descrizioneBreve {
          childMarkdownRemark {
            html
          }
        }
        descrizioneAggiuntiva {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
